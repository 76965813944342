<template>
  <LcBadge v-bind="CONFIG[status]">
    {{ $t(`myorders.status.playslip.${status}`) }}
  </LcBadge>
</template>

<script lang="ts" setup>
import { LcBadge, type LcIconName } from '@lottocom/frontend-components'

import { PlaySlipStatus } from '~/@types/generated/backend/graphql-schema-types'

type StatusBadge = {
  class: string
  icon: LcIconName
}

type MyOrdersStatusBadgeSingleOrderProps = {
  status: PlaySlipStatus
}

defineProps<MyOrdersStatusBadgeSingleOrderProps>()

const CONFIG: Record<PlaySlipStatus, StatusBadge> = {
  [PlaySlipStatus.Cancelled]: {
    class: 'bg-danger100',
    icon: 'orders-canceled',
  },
  [PlaySlipStatus.Closed]: {
    class: 'bg-danger100',
    icon: 'orders-purchased',
  },
  [PlaySlipStatus.ManualReview]: {
    class: 'bg-secondary300',
    icon: 'orders-waiting-for-purchase',
  },
  [PlaySlipStatus.Ordered]: {
    class: 'bg-secondary300',
    icon: 'orders-waiting-for-purchase',
  },
  [PlaySlipStatus.Preliminary]: {
    class: 'bg-secondary300',
    icon: 'orders-waiting-for-purchase',
  },
  [PlaySlipStatus.Purchased]: {
    class: 'bg-secondary400',
    icon: 'orders-purchased',
  },
  [PlaySlipStatus.Rejected]: {
    class: 'bg-danger100',
    icon: 'orders-canceled',
  },
}
</script>
