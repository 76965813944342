<template>
  <MyOrdersStatusInfoButton
    v-if="isInfoButtonVisible"
    v-bind="{ status }"
    type="playslip"
  />
  <MyOrdersStatusBadgeSingleOrder v-if="isBadgeVisible" v-bind="{ status }" />
</template>

<script lang="ts" setup>
import { PlaySlipStatus } from '~/@types/generated/backend/graphql-schema-types'

type MyOrdersStatusProps = {
  status: PlaySlipStatus
}

const props = defineProps<MyOrdersStatusProps>()

const isBadgeVisible = computed(() => props.status !== PlaySlipStatus.Closed)
const isInfoButtonVisible = computed(
  () =>
    ![
      PlaySlipStatus.Cancelled,
      PlaySlipStatus.Closed,
      PlaySlipStatus.Rejected,
    ].includes(props.status),
)
</script>
